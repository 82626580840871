<template>
  <div class="authentication-basic">
    <el-card v-show="!isQrCode" class="content">
      <div class="tips">
        检测到您的认证信息不全，为满足法律法规和主管部门要求，保护您的合法权益，我们需要获取您的姓名和身份信息进行实名认证。为不影响您的产品体验，请尽快完善信息。
      </div>
      <el-form ref="authForm" :rules="authRule" :model="authForm" size="small">
        <el-form-item prop="name" label="姓名" label-width="6em">
          <el-input
            v-model="authForm.name"
            placeholder="请您输入姓名"
          />
        </el-form-item>
        <el-form-item prop="card" label="身份证号" label-width="6em">
          <el-input
            v-model="authForm.card"
            placeholder="请您输入身份证号"
          />
        </el-form-item>
      </el-form>
      <el-button plain :disabled="isDisabled" @click="submit">提交并进行认证</el-button>
    </el-card>
    <el-card v-show="isQrCode" class="content">
      <div class="tips">
        检测到您的认证信息不全，为满足法律法规和主管部门要求，保护您的合法权益，我们需要获取您的姓名和身份信息进行实名认证。为不影响您的产品体验，请尽快完善信息。
      </div>
      <div id="codeAuth" />
      <div class="code-tips">请使用【支付宝APP】扫描二维码完善实名认证</div>
      <el-button plain :disabled="isDisabledQuery" @click="queryAuth">查询认证</el-button>
    </el-card>
  </div>
</template>

<script>
import validate from '@/utils/baseValidate.js'
import QRCode from 'qrcodejs2'
import { ElMessage } from 'element-plus'
import { getAuthResult, authCode } from '@/api/auth'
import { getInfo } from '@/api/user'
export default {
  data() {
    return {
      isDisabled: false,
      isQrCode: false,
      isDisabledQuery: false,
      authForm: {
        name: '',
        card: ''
      },
      authRule: {
        name: [
          { required: true, trigger: 'blur', validator: validate.linkName }
        ],
        card: [
          { required: true, trigger: 'blur', validator: validate.idCard }
        ]
      }
    }
  },
  // mounted() {
  //   document.getElementById('codeAuth').innerHTML = ''
  //   this.codeAuth = new QRCode(document.getElementById('codeAuth'), {
  //     // text: res.data.url,
  //     text: 'https://openapi.alipay.com/gateway.do?alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6&alipay_sdk=alipay-sdk-java-dynamicVersionNo&app_cert_sn=73b30bd4529caa644e2e9d869b7905c9&app_id=2021003168686858&biz_content=%7B%22certify_id%22%3A%2292520265e365c3dc86aaa00f37a099ad%22%7D&charset=utf-8&format=JSON&method=alipay.user.certify.open.certify&sign=vkTIY7up39I8DWsf7EGwAn6spgAipmzpyISjUeNnU6lyXnVSJzNDbrVUUqHHso%2FGzROQMMi4li4lTU%2FQLgBRKDP5%2F%2Fm36Z3hcuvT4izriMC%2FmsFikv7yHdfd8YpX1aKTKJtL2aDEuwlN2scEJyw3%2B%2BiEdh3e6vCIeshpCoFAm1Ghos%2BFgaJt4HRdRYALig9yqYmCulXpWm7eVSOxrNePsFeqlpHS21xoNUIpMIuA5t4PHjXpWzy5EjWZ%2BstPcNOljkowboXYF0KGGAOIZetzp%2BH2Ufwr9%2FIu%2BqKYrcBb%2FZ7rZTxe6ttwL8Jxl%2BNRTDo%2FBi5N8Iynqhyruli0N4p1xw%3D%3D&sign_type=RSA2&timestamp=2023-04-06+18%3A19%3A48&version=1.0',
  //     width: 260,
  //     height: 260,
  //     colorDark: '#000',
  //     colorLight: '#fff',
  //     correctLevel: QRCode.CorrectLevel.H
  //   })
  //   this.isQrCode = true
  // },
  methods: {
    // 获取info接口
    getInfo() {
      getInfo().then((res) => {
        if (res.meta.status === 200) {
          this.$store.commit('user/SET_AUTH', res.data.auth)
        }
      })
    },
    // 查询认证
    queryAuth() {
      getAuthResult().then((res) => {
        if (res.meta.status === 200) {
          ElMessage.success('认证成功')
          this.getInfo()
          this.$emit('queryAuthResult', 'success')
        } else {
          this.isQrCode = false
          ElMessage.error(res.meta.msg)
          this.$emit('queryAuthResult', 'fail')
        }
      }).catch(() => {
        this.isQrCode = false
        this.$emit('queryAuthResult', 'fail')
      })
    },
    // 提交身份信息 获取支付宝二维码
    submit() {
      const data = {
        idcard: this.authForm.card,
        name: this.authForm.name
      }
      this.$refs.authForm.validate((valid) => {
        if (valid) {
          this.isDisabled = true
          this.$nextTick(() => {
            authCode(data).then((res) => {
              if (res.meta.status === 200) {
                document.getElementById('codeAuth').innerHTML = ''
                this.codeAuth = new QRCode(document.getElementById('codeAuth'), {
                  text: res.data.url,
                  // text: 'https://openapi.alipay.com/gateway.do?alipay_root_cert_sn=687b59193f3f462dd5336e5abf83c5d8_02941eef3187dddf3d3b83462e1dfcf6&alipay_sdk=alipay-sdk-java-dynamicVersionNo&app_cert_sn=73b30bd4529caa644e2e9d869b7905c9&app_id=2021003168686858&biz_content=%7B%22certify_id%22%3A%2292520265e365c3dc86aaa00f37a099ad%22%7D&charset=utf-8&format=JSON&method=alipay.user.certify.open.certify&sign=vkTIY7up39I8DWsf7EGwAn6spgAipmzpyISjUeNnU6lyXnVSJzNDbrVUUqHHso%2FGzROQMMi4li4lTU%2FQLgBRKDP5%2F%2Fm36Z3hcuvT4izriMC%2FmsFikv7yHdfd8YpX1aKTKJtL2aDEuwlN2scEJyw3%2B%2BiEdh3e6vCIeshpCoFAm1Ghos%2BFgaJt4HRdRYALig9yqYmCulXpWm7eVSOxrNePsFeqlpHS21xoNUIpMIuA5t4PHjXpWzy5EjWZ%2BstPcNOljkowboXYF0KGGAOIZetzp%2BH2Ufwr9%2FIu%2BqKYrcBb%2FZ7rZTxe6ttwL8Jxl%2BNRTDo%2FBi5N8Iynqhyruli0N4p1xw%3D%3D&sign_type=RSA2&timestamp=2023-04-06+18%3A19%3A48&version=1.0',
                  width: 300,
                  height: 300,
                  colorDark: '#000',
                  colorLight: '#fff',
                  correctLevel: QRCode.CorrectLevel.H
                })
                this.isQrCode = true
                this.isDisabled = false
              } else {
                this.isDisabled = false
                ElMessage.error(res.meta.msg)
              }
            }).catch(() => {
              this.isDisabled = false
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.authentication-basic{
  display: flex;
  justify-content: center;
  align-items: center;
  .content{
    width: 500px;
    text-align: center;
    ::v-deep .el-button{
      padding: 2px 21px;
      min-height: 26px;
      border: 1px solid #165DFF;
      color: #165DFF;
      margin-top: 15px;
      margin-bottom: 18px;
    }
    .tips{
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #909399;
      line-height: 20px;
      text-align: left;
      margin-bottom: 16px;
    }
    #codeAuth{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .code-tips{
      margin-top: 8px;
      margin-bottom: 13px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #303133;
      line-height: 22px;
    }
  }
}
</style>
