import request from '@/utils/requestAuth'
const auth = '/service-auth-provider/v1'

// 认证结果查询
export function getAuthResult(data) {
  return request({
    url: auth + '/certify/check',
    method: 'get'
  })
}
// 认证返回二维码
export function authCode(data) {
  return request({
    url: auth + '/certify/url',
    method: 'post',
    data
  })
}
